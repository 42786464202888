// **********************************************************************
// QUIZ
// **********************************************************************
function quiz() {
	const quizBlock = document.getElementsByClassName('quiz');
	const questionCount = document.getElementsByClassName('quiz__item--question').length;

	if (quizBlock.length = 0) return 0
	
	for (const quiz of quizBlock) {
		const answerInputs = quiz.getElementsByClassName('quiz__answer');
		const showAnswers = quiz.getElementsByClassName('quiz__show-results')[0];

		// End of quiz - can add a class to the block that reveals correct & incorrect answers
		showAnswers.onclick = function() {
			quiz.classList.add('quiz--reveal')
			quiz.scrollIntoView(true)
		}

		// Loop over all answers & add selected classes to the group & individual answers. 
		// If the group has a 'selected' class then it initiates the animation to show the next question
		// If the ansewe has a 'selected' class then it is referenced later to calculate the overall result
		for (const answer of answerInputs) {
			const answerGroup = answer.closest(".quiz__item");
			// The height is used to scroll to the next question
			const answerGroupHeight = answer.closest(".quiz__item").offsetHeight;
			const answerSiblings = answer.closest("fieldset").getElementsByClassName('quiz__answer');

			answer.onclick = function() {
				for (const sibling of answerSiblings) {
					sibling.classList.remove('quiz__answer--selected');
				}
				answer.classList.add('quiz__answer--selected');
				answerGroup.classList.add('quiz__item--selected');

				// After 0.8s scroll the window. This is in time with the css animation to create a smooth transition
				setTimeout(
					function() {
						window.scrollBy(0,answerGroupHeight)
					}, 800);

				// If the clicked answer is in the final question group then the result is calculated
				if (answerGroup.classList.contains("quiz__item--last")) {
					let result= 0
					const selectedAnswers = quiz.getElementsByClassName('quiz__answer--selected');
					if (selectedAnswers.length = 0) return 0

					for (const correctAnswer of selectedAnswers) {
						if (correctAnswer.value == 1) {
							result++
						}
					}

					const percentage = Math.round(result / questionCount * 100)
					const percentageResult = percentage + '%'
					const resultBar = quiz.getElementsByClassName('quiz__result-bar')[0];
					const resultQty = quiz.getElementsByClassName('quiz__qty-correct')[0];
					const resultPercent = quiz.getElementsByClassName('quiz__percent-correct')[0];

					resultPercent.innerHTML = percentageResult
					resultBar.style.width = percentageResult
					resultQty.innerHTML = result
				}
			}
		}
	}
}

quiz();




// **********************************************************************
// TOGGLE THE MOBILE NAV
// **********************************************************************
function navToggle() {
	if (window.innerWidth > 1250) return 0

	const navToggle = document.getElementsByClassName('js-nav-toggle');
	const nav = document.getElementsByClassName('site-navigation__list')[0];
	const body = document.getElementsByClassName('body')[0];

	if (navToggle.length = 0) return 0
	if (nav.length = 0) return 0

	for (const toggle of navToggle) {
		toggle.onclick = function() {
			if (nav.classList.contains("site-navigation__list--active")) {
				toggle.classList.remove("site-navigation__toggle--active")
				nav.classList.remove("site-navigation__list--active")
				body.classList.remove("body--fixed")
			} else {
				toggle.classList.add("site-navigation__toggle--active")
				nav.classList.add("site-navigation__list--active")
				body.classList.add("body--fixed")
			}
		}
	}
}

// **********************************************************************
// Gets the height of the header & assigns it to subnav 'top' style
// **********************************************************************
function subnavOffset() {
	if (window.innerWidth < 1250) return 0
	const headerHeight = document.getElementsByClassName('header')[0].offsetHeight;
	const subNavs = document.getElementsByClassName('site-navigation__subnav');
	const subNavSpacers = document.getElementsByClassName('site-navigation__subnav-hover-spacer');

	for (const item of subNavs) {
		item.style.top = headerHeight + "px"
	}

	for (const item of subNavSpacers) {
		item.style.height = headerHeight / 2 - 10 + "px"
	}
}

// **********************************************************************
// Controlls the accordion toggle
// **********************************************************************
function accordion() {
	const accordionTrigger = document.getElementsByClassName('js-accordion-trigger');
	const accordionDraw = document.getElementsByClassName('js-accordion-draw');
	
	if (accordionTrigger.length = 0) return 0

	for (const draw of accordionDraw) {
		const drawHeight = draw.offsetHeight
		draw.setAttribute('height', drawHeight);
		draw.style.height = 0
	}

	for (const trigger of accordionTrigger) {
		const closestDraw = trigger.closest('.accordion__heading').nextElementSibling;
		const closestDrawHeight = closestDraw.getAttribute('height')

		trigger.onclick = function() {
			if (trigger.classList.contains("accordion__button--active")) {
				trigger.classList.remove("accordion__button--active")
				closestDraw.style.height = 0
			} else {
				trigger.classList.add("accordion__button--active")
				closestDraw.style.height = closestDrawHeight + "px"
			}
		}
	}
}

// **********************************************************************
// Controlls the mobile subnav toggle
// **********************************************************************
function mobileSubnav() {
	if (window.innerWidth > 1250) return 0

	const accordionTrigger = document.getElementsByClassName('js-sub-nav-toggle');
	const accordionDraw = document.getElementsByClassName('site-navigation__subnav');
	
	if (accordionTrigger.length = 0) return 0

	for (const draw of accordionDraw) {
		const drawHeight = draw.offsetHeight
		draw.setAttribute('height', drawHeight);
		draw.style.height = 0
	}

	for (const trigger of accordionTrigger) {
		const closestDraw = trigger.nextElementSibling;
		const closestDrawHeight = closestDraw.getAttribute('height')

		trigger.onclick = function() {
			if (trigger.classList.contains("site-navigation__subnav-toggle--active")) {
				trigger.classList.remove("site-navigation__subnav-toggle--active")
				closestDraw.style.height = 0
			} else {
				trigger.classList.add("site-navigation__subnav-toggle--active")
				closestDraw.style.height = closestDrawHeight + "px"
			}
		}
	}
}

// **********************************************************************
// Sets a consistent scroll speed when different ammounts of text gets added to the marquee
// **********************************************************************
function marquee() {
	const marqueeElement = document.getElementsByClassName('js-marquee');
	if (marqueeElement.length = 0) return 0

	for (const child of marqueeElement) {
		const marqueeChild = child.querySelectorAll('p')
		let count = 0
		for (const item of marqueeChild) {
			count = item.innerText.length + count
		}
		count = count / 2.6
		child.style.animationDuration = count + "s"
	}
}


// **********************************************************************
// Modal toggle
// **********************************************************************
function modal() {
	const modalTrigger = document.getElementsByClassName('js-modal-toggle');
	const modal = document.getElementsByClassName('js-modal')[0];
	
	if (modalTrigger == null) return 0
	if (modal == null) return 0

	for (const trigger of modalTrigger) {
		trigger.onclick = function() {
			if (modal.classList.contains("modal--active")) {
				modal.classList.remove("modal--active")
			} else {
				modal.classList.add("modal--active")
			}
		}
	}
}

function search() {
	const control = document.querySelector('.search__control');
	const form = document.querySelector('.search__form')

	if ((control == null) || (form == null)) return 0;

	control.addEventListener('click', function(e) {
		console.log("click")
		if (control.classList.contains('hidden') && form.classList.contains('visible')) {
			form.classList.remove('visible');
			control.classList.remove('hidden');
		} else {
			control.classList.add('hidden');
			form.classList.add('visible');
		}
	});
}

const initCheckoutForm = () => {

	const form = document.querySelector('[data-checkout-form]');

	if ( ! form ) return;
	
	form.querySelectorAll('[data-proxy-target]').forEach(checkbox => {
		const target = form.querySelector('[name="' + checkbox.dataset.proxyTarget + '"]');
		checkbox.addEventListener('change', () => target.value = checkbox.checked ? 1 : 0);
	});
	
};

const initPaymentForm = () => {
	
	if (typeof window.stripe === 'undefined') return;
	
	const elements = window.stripe.elements();
	const style = {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4'
			},
		},
		invalid: {
			color: '#FF0000',
			iconColor: '#FF0000'
		}
	};

	const form = document.querySelector('[data-payment-form]');
	const card = elements.create('card', { hidePostalCode: true, style });
	const errorWrapper = document.querySelector('[data-stripe-errors]');
	const submitInput =  form.querySelector('button[type="submit"]');
	const paymentMethodInput = form.querySelector('input[name="' + form.dataset.paymentFormNamespace  + '[paymentMethodId]"]');
	
	card.mount('[data-stripe-payment-form]');
	card.on('change', (event) => errorWrapper.textContent = event.error ? event.error.message : '');
	
	form.addEventListener('submit', (e) => {
		
		e.preventDefault();

		submitInput.setAttribute('disabled', 'true');

		const paymentData = {
			billing_details: {
				email: form.dataset.cartEmail
			}
		};
		
		window.stripe.createPaymentMethod('card', card, paymentData).then((res) => {
			if (res.error) {
				errorWrapper.textContent = res.error.message;
				submitInput.removeAttribute('disabled');
			} else {
				paymentMethodInput.setAttribute('value', res.paymentMethod.id);
				form.submit();
			}
		});
		
	});
	
}

// **********************************************************************
// Subscription button
// **********************************************************************
const initSubscriptionButton = () => {
	const button = document.querySelector('[data-js="subscribe-button"]');
	if (!button) {
		console.log('No subscription button found');
		return;
	} 
	const form = button.closest('form');
	if (!form) {
		console.log('No form found for subscription button');
		return;
	}
	let allowSubmission = true;
	form.addEventListener('submit', () => {
		if (allowSubmission) {
			button.setAttribute('disabled', 'true');
			allowSubmission = false;
			return;
		}
		preventDefault();
	});
	
};

// **********************************************************************
// **********************************************************************
document.addEventListener("DOMContentLoaded", function(){
	navToggle();
	subnavOffset();
	mobileSubnav();
	accordion();
	marquee();
	modal();
	search();
	initCheckoutForm();
	initPaymentForm();
	initSubscriptionButton();
});